import {
  Avatar, ListManager,
  PageHeader, Paginator, Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import { ANIMAL, CASTRATION } from '@/lib/enums'
import dateMask from '@/lib/masks/date'
import phoneNumber from '@/lib/masks/phoneNumber'
import castrations from '@/services/api/castrations'
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import CastrationsQuickFilter from '../components/QuickFilter'
import mapTitles from './mapTitles'
import DateSelect from './DateSelect'
import CastrationsFilter from '../components/Filter'

const mappingDates = [
  { label: 'Solicitado em', key: 'createdAt' },
  { label: 'Aprovado em', key: 'approvedAt' },
  { label: 'Cancelado em', key: 'canceledAt' },
  { label: 'Iniciado em', key: 'startedAt' },
  { label: 'Finalizado em', key: 'performedAt' },
  { label: 'Agendado para', key: 'scheduledTo' },
  { label: 'Rejeitado em', key: 'rejectedAt' },
]

function Status({ title }) {
  usePageTitle(title)
  const params = useParams()
  const query = useQuery()
  const [dateSelect, setDateSelect] = useState(mappingDates[0])
  const { status } = params
  const { data, loading, error } = useAxiosRequest(castrations.list, {
    status,
    ...query,
  })

  const titleParams = mapTitles(params.status)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title={titleParams.title}
        icon={titleParams.icon}
        subtitle={titleParams.subtitle}
        rightAction={() => (
          <CastrationsFilter />
        )}
      />
      <CastrationsQuickFilter />
      <ListManager loading={loading} count={data?.count || 0} error={error}>
        <>
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th width="1">#</th>
                <th>Solicitante/Tutor</th>
                <th>Pet</th>
                <th>Clínica</th>
                <th>
                  <DateSelect
                    onSelect={setDateSelect}
                    mapping={mappingDates}
                    selected={dateSelect}
                  />
                </th>
                <th width="100px" className="text-right">Ações</th>
              </tr>
            </thead>
            <tbody>
              {
                Boolean(data) && data.rows.map((castration) => (
                  <tr key={castration.id}>
                    <td className={`${castration?.position <= 3 ? 'text-primary text-bold' : ''}`}>{castration?.position}º</td>
                    <td>
                      {castration?.tutorId === castration?.animal?.tutorId ? (
                        <>
                          <Avatar
                            avatarSize="sm"
                            className="mr-1"
                            name={castration?.tutor?.fullName}
                            photoURL={castration?.tutor?.photoURL}
                            alt="Imagem de usuário"
                          />
                          {castration?.tutor?.fullName}
                          <div>
                            <small>
                              {phoneNumber(castration?.tutor?.phoneNumber)}
                            </small>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="text-small">
                            <small>Solicitante:</small>
                          </div>
                          <Avatar
                            avatarSize="sm"
                            className="mr-1"
                            name={castration?.tutor?.fullName}
                            photoURL={castration?.tutor?.photoURL}
                            alt="Imagem de usuário"
                          />
                          {castration?.tutor?.fullName}
                          <div>
                            <small>
                              {phoneNumber(castration?.tutor?.phoneNumber)}
                            </small>
                          </div>
                          <div className="text-small text-bold">
                            <small>Tutor atual:</small>
                          </div>
                          <Avatar
                            avatarSize="sm"
                            className="mr-1"
                            name={castration?.animal?.tutor?.fullName}
                            photoURL={castration?.animal?.tutor?.photoURL}
                            alt="Imagem de usuário"
                          />
                          {castration?.animal?.tutor?.fullName}
                          <div>
                            <small>
                              {phoneNumber(
                                castration?.animal?.tutor?.phoneNumber,
                              )}
                            </small>
                          </div>
                        </>
                      )}
                    </td>
                    <td>
                      <Link to={`/app/pets/${castration?.animalId}`}>
                        <Avatar
                          avatarSize="sm"
                          className="mr-1"
                          name={castration?.animal?.name}
                          photoURL={castration?.animal?.photoURL}
                          smart={false}
                          alt="Imagem do animal"
                        />
                        {castration?.animal?.name}
                      </Link>
                      <div>
                        <small>{castration?.animal?.category?.description}</small>
                        <small> - {ANIMAL.gender.t(castration?.animal?.gender)}</small>
                        <small> - {ANIMAL.size.t(castration?.animal?.size)}</small>
                      </div>
                      <div>
                        <small>{castration?.animal?.breed?.description}</small>
                      </div>
                      {
                        castration?.releasedAt && new Date(castration.releasedAt) > new Date() && (
                          <div>
                            <small className="text-error">
                              <i className="fas fa-exclamation-triangle" />&nbsp;
                              Pet alcaçará idade mínima para castração em:&nbsp;
                              <b>{dateMask(castration?.releasedAt, { dateStyle: 'short' })}</b>
                            </small>
                          </div>
                        )
                      }
                    </td>
                    <td>
                      {
                        castration?.clinic ? (
                          <>
                            <Avatar
                              avatarSize="sm"
                              className="mr-1"
                              name={castration?.clinic?.fullName}
                              photoURL={castration?.clinic?.photoURL}
                              alt="Imagem de usuário"
                            />
                            {castration?.clinic?.fullName || '-'}
                            <div>
                              <small>{phoneNumber(castration?.clinic?.phoneNumber)}</small>
                            </div>
                          </>
                        ) : '-'
                      }
                    </td>
                    <td>
                      <div>{dateMask(castration[dateSelect.key], { hour: '2-digit', minute: '2-digit' })}</div>
                      <small>{CASTRATION.status.t(castration.status)}</small>
                    </td>
                    <td>
                      <div className="d-flex align-center" style={{ justifyContent: 'flex-end' }}>
                        {
                          castration.countComments > 0 && (
                            <div className="tooltip tooltip-left" data-tooltip="Comentários na castração">
                              <span className="badge mr-2" data-badge={castration.countComments}>
                                <i className="fas fa-comment" />
                              </span>
                            </div>
                          )
                        }
                        <Link
                          to={`/app/castrations/status/${params?.status}/${castration.id}`}
                          className="btn btn-action btn-sm btn-primary"
                        >
                          <i className="fas fa-eye" />
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          {
            Boolean(data) && (
              <div className="float-right my-2">
                <Paginator paginatedList={data} />
              </div>
            )
          }
        </>
      </ListManager>
    </Styles.Container>
  )
}

export default Status
