import { Flexbox, Form, LoadManager } from '@/components'
import useCastration from '@/hooks/useCastration'
import usePageTitle from '@/hooks/usePageTitle'
import { CASTRATION_CORRECTION_REQUIRED } from '@/lib/enums'
import yup from '@/lib/yupPt'
import { setCastration } from '@/reducers/castration'
import castrations from '@/services/api/castrations'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  description: yup.string().trim().required().min(10),
  correctionRequired: yup.array().of(yup.string().required()).min(1, 'Selecione ao menos 1 item'),
})
function Correction({ title }) {
  usePageTitle({ title })
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const { state: { castration }, dispatch } = useCastration()
  const history = useHistory()

  async function correction({ description, correctionRequired }) {
    try {
      setLoading(true)
      const { data } = await castrations.correction(id,
        { eventData: { description, correctionRequired } })
      dispatch(setCastration(data))
      toast.success('Castração enviada para correção')
      history.goBack()
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <fieldset disabled={loading}>
      <Form.Formik
        initialValues={{ description: '', correctionRequired: [] }}
        validationSchema={schema}
        onSubmit={correction}
        validateOnMount
      >
        {
          ({
            errors, touched, isValid,
          }) => (
            <Form.ValidationForm>
              <div className="modal active" id="modal-id">
                <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
                <div className="modal-container">
                  <div className="modal-header">
                    <a
                      type="button"
                      className="btn btn-clear float-right"
                      onClick={() => history.goBack()}
                    />
                    <div className="modal-title h5">Enviar para correção a castração #{id}</div>
                  </div>
                  <div className="modal-body">
                    <div className="content">
                      <LoadManager loading={!castration}>
                        <>
                          <p>Confirma o envio da castração de <b className="text-primary">{castration?.animal?.name}</b> para correção?</p>
                          <p className="text-small">
                            Esta ação vai gerar um evento de correção e avisará ao usuário o motivo.
                          </p>
                          <p className="text-small">
                            Utilize esta funcionalidade
                            <b> apenas quando tiver dados incorretos na solicitação</b>.
                          </p>
                          <Form.Label htmlFor="correctionRequired">
                            Selecione os items que o tutor deve, obrigatoriamente,
                            corrigir: <span className="text-error">*</span>
                          </Form.Label>
                          <Flexbox.Columns className="align-center">
                            {
                              Object.values(CASTRATION_CORRECTION_REQUIRED.type.enum)
                                .map((i) => (
                                  <Flexbox.Column className="col-12" key={i}>
                                    <Form.Group className={`${touched?.correctionRequired && errors?.correctionRequired ? 'has-error' : ''}`}>
                                      <Form.Check className="form-checkbox" style={{ marginTop: -8 }}>
                                        <Form.ValidationField
                                          type="checkbox"
                                          name="correctionRequired"
                                          id={i}
                                          value={i}
                                        />
                                        <i className="form-icon" />
                                        &nbsp;{CASTRATION_CORRECTION_REQUIRED.type.t(i)}
                                      </Form.Check>
                                    </Form.Group>
                                  </Flexbox.Column>
                                ))
                            }
                            {
                              touched?.correctionRequired
                                && errors?.correctionRequired
                                ? (
                                  <Flexbox.Column className="col-12" style={{ marginTop: -8 }}>
                                    <Form.Hint className="text-error">{errors.correctionRequired}</Form.Hint>
                                  </Flexbox.Column>
                                )
                                : null
                            }
                          </Flexbox.Columns>
                          <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="description">Descreva o que deverá ser corrigido <span className="text-error">*</span></Form.Label>
                            <Form.ValidationField
                              name="description"
                              component="textarea"
                              id="description"
                              placeholder="Para aprovarmos a castração é necessário corrigir ..."
                            />
                            {
                              touched?.description && errors?.description
                                ? <Form.Hint className="text-error">{errors.description}</Form.Hint>
                                : null
                            }
                          </Form.Group>
                        </>
                      </LoadManager>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <a
                      className="btn mr-2"
                      disabled={loading}
                      onClick={() => history.goBack()}
                    >
                      <i className="fas fa-chevron-left" />
                      &nbsp;Voltar
                    </a>
                    <button
                      className={`btn btn-primary ${loading ? 'loading' : ''}`}
                      type="submit"
                      disabled={!isValid}
                    >
                      <i className="fas fa-edit" />
                      &nbsp;Enviar para correção
                    </button>
                  </div>
                </div>
              </div>
            </Form.ValidationForm>
          )
        }
      </Form.Formik>
    </fieldset>
  )
}

export default Correction
