import { Filter, Form, Mask } from '@/components'
import envs from '@/constants/envs'
import React from 'react'
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete'

function CastrationsFilter() {
  const onChangePlace = ({ value }, setFieldValue) => {
    geocodeByPlaceId(value.place_id).then((results) => {
      const northEast = results[0]?.geometry.bounds.getNorthEast()
      const southWest = results[0]?.geometry.bounds.getSouthWest()
      setFieldValue('bounds', {
        value,
        northEast: { lat: northEast.lat(), lng: northEast.lng() },
        southWest: { lat: southWest.lat(), lng: southWest.lng() },
      })
    })
  }
  return (
    <>
      <Filter
        initialValues={{
          castrationUid: '',
          hideUnreleased: false,
          tutorName: '',
          tutorEmail: '',
          bounds: null,
          tutorPhoneNumber: '',
          animalName: '',
          clinicName: '',
          clinicDocumentNumber: '',
          animalUid: '',
          tutorDocumentNumber: '',
          animalTutorName: '',
          animalTutorEmail: '',
          animalTutorPhoneNumber: '',
          animalTutorDocumentNumber: '',
        }}
        title="Filtrar castrações"
        renderForm={({ setFieldValue }) => (
          <>
            <Form.Group>
              <Form.Label className="text-sm" htmlFor="castrationUid">
                Código da castração
              </Form.Label>
              <Form.ValidationField name="castrationUid">
                {({ field }) => (
                  <Mask.Rga
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    className="input-sm"
                    placeholder="####.####.####.####.####"
                    id="castrationUid"
                    onAccept={(value) => {
                      setFieldValue('castrationUid', value)
                    }}
                  />
                )}
              </Form.ValidationField>
            </Form.Group>
            <Form.Group>
              <Form.Switch
                className="form-switch"
                key="hideUnreleased"
                style={{ marginTop: 15 }}
              >
                <Form.ValidationField
                  type="checkbox"
                  name="hideUnreleased"
                  id="hideUnreleased"
                />
                <i className="form-icon" />
                &nbsp;Ocultar castrações não liberadas
              </Form.Switch>
            </Form.Group>
            <div className="divider" />
            <Form.Group>
              <Form.Label className="text-sm" htmlFor="bounds">
                Localização
              </Form.Label>
              <Form.ValidationField name="bounds">
                {({ field }) => (
                  <GooglePlacesAutocomplete
                    selectProps={{
                      onChange: (value) => onChangePlace(value, setFieldValue),
                      placeholder: 'Informe a região',
                    }}
                    apiOptions={{
                      language: 'pt-BR',
                    }}
                    id="bounds"
                    apiKey={envs.REACT_APP_GOOGLE_MAPS_API_KEY}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                  />
                )}
              </Form.ValidationField>
            </Form.Group>
            <div className="pt-1">
              <div className="divider text-center" data-content="Solicitante" />
            </div>
            <Form.Group>
              <Form.Label className="text-sm" htmlFor="tutorName">
                Nome do solicitante
              </Form.Label>
              <Form.ValidationField
                name="tutorName"
                className="input-sm"
                autoFocus
                id="tutorName"
                autoComplete="none"
                placeholder="Fulano da Silva"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="text-sm" htmlFor="tutorEmail">
                Email do solicitante
              </Form.Label>
              <Form.ValidationField
                name="tutorEmail"
                className="input-sm"
                autoFocus
                id="tutorEmail"
                autoComplete="none"
                placeholder="fulano@email.com"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="text-sm" htmlFor="tutorPhoneNumber">
                Telefone do solicitante
              </Form.Label>
              <Form.ValidationField name="tutorPhoneNumber">
                {({ field }) => (
                  <Mask.Phone
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    className="input-sm"
                    placeholder="Número do telefone"
                    id="tutorPhoneNumber"
                    onAccept={(value) => {
                      setFieldValue('tutorPhoneNumber', value)
                    }}
                  />
                )}
              </Form.ValidationField>
            </Form.Group>
            <Form.Group>
              <Form.Label className="text-sm" htmlFor="tutorDocumentNumber">
                CPF/CNPJ do solicitante
              </Form.Label>
              <Form.ValidationField name="tutorDocumentNumber">
                {({ field }) => (
                  <Mask.CpfCnpj
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    className="input-sm"
                    placeholder="CPF/CNPJ do tutor"
                    id="tutorDocumentNumber"
                    onAccept={(value) => {
                      setFieldValue('tutorDocumentNumber', value)
                    }}
                  />
                )}
              </Form.ValidationField>
            </Form.Group>
            <div className="pt-1">
              <div className="divider text-center" data-content="Tutor" />
            </div>
            <Form.Group>
              <Form.Label className="text-sm" htmlFor="animalTutorName">
                Nome do tutor
              </Form.Label>
              <Form.ValidationField
                name="animalTutorName"
                className="input-sm"
                autoFocus
                id="animalTutorName"
                autoComplete="none"
                placeholder="Fulano da Silva"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="text-sm" htmlFor="animalTutorEmail">
                Email do tutor
              </Form.Label>
              <Form.ValidationField
                name="animalTutorEmail"
                className="input-sm"
                autoFocus
                id="animalTutorEmail"
                autoComplete="none"
                placeholder="fulano@email.com"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="text-sm" htmlFor="animalTutorPhoneNumber">
                Telefone do tutor
              </Form.Label>
              <Form.ValidationField name="animalTutorPhoneNumber">
                {({ field }) => (
                  <Mask.Phone
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    className="input-sm"
                    placeholder="Número do telefone"
                    id="animalTutorPhoneNumber"
                    onAccept={(value) => {
                      setFieldValue('animalTutorPhoneNumber', value)
                    }}
                  />
                )}
              </Form.ValidationField>
            </Form.Group>
            <Form.Group>
              <Form.Label className="text-sm" htmlFor="animalTutorDocumentNumber">
                CPF/CNPJ do tutor
              </Form.Label>
              <Form.ValidationField name="animalTutorDocumentNumber">
                {({ field }) => (
                  <Mask.CpfCnpj
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    className="input-sm"
                    placeholder="CPF/CNPJ do tutor"
                    id="animalTutorDocumentNumber"
                    onAccept={(value) => {
                      setFieldValue('animalTutorDocumentNumber', value)
                    }}
                  />
                )}
              </Form.ValidationField>
            </Form.Group>
            <div className="pt-1">
              <div className="divider text-center" data-content="Pet" />
            </div>
            <Form.Group>
              <Form.Label className="text-sm" htmlFor="animalName">
                Nome do animal
              </Form.Label>
              <Form.ValidationField
                name="animalName"
                className="input-sm"
                id="animalName"
                autoComplete="none"
                placeholder="Totó da Silva"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="text-sm" htmlFor="animalUid">
                RGA
              </Form.Label>
              <Form.ValidationField name="animalUid">
                {({ field }) => (
                  <Mask.Rga
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    className="input-sm"
                    placeholder="####.####.####.####.####"
                    id="animalUid"
                    onAccept={(value) => {
                      setFieldValue('animalUid', value)
                    }}
                  />
                )}
              </Form.ValidationField>
            </Form.Group>
            <div className="pt-1">
              <div className="divider text-center" data-content="Clínica" />
            </div>
            <Form.Group>
              <Form.Label className="text-sm" htmlFor="clinicName">
                Nome da clínica
              </Form.Label>
              <Form.ValidationField
                name="clinicName"
                className="input-sm"
                autoFocus
                id="clinicName"
                autoComplete="none"
                placeholder="Clínica do pet"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="text-sm" htmlFor="clinicDocumentNumber">
                CPF/CNPJ da clínica
              </Form.Label>
              <Form.ValidationField name="clinicDocumentNumber">
                {({ field }) => (
                  <Mask.CpfCnpj
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    className="input-sm"
                    placeholder="CPF/CNPJ da clínica"
                    id="clinicDocumentNumber"
                    onAccept={(value) => {
                      setFieldValue('clinicDocumentNumber', value)
                    }}
                  />
                )}
              </Form.ValidationField>
            </Form.Group>
          </>
        )}
      />
    </>
  )
}

export default CastrationsFilter
